var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',[_c('CRow',[_c('CCol',{attrs:{"sm":"12","lg":"5"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.crane'),"horizontal":{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'},"options":_vm.optionListGruas,"is-valid":_vm.hasError(_vm.$v.CraneFiltre),"invalid-feedback":_vm.errorMessage(_vm.$v.CraneFiltre),"addLabelClasses":"text-right required"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.CraneFiltre)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content:_vm.$t('label.nueva')+' '+_vm.$t('label.crane'),
                                        placement: 'top-end'
                                    }),expression:"{\n                                        content:$t('label.nueva')+' '+$t('label.crane'),\n                                        placement: 'top-end'\n                                    }"}],staticClass:"rounded",attrs:{"color":"add","size":"sm"},on:{"click":function($event){return _vm.activarModal()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1):_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                        content:_vm.$t('label.toView')+' '+_vm.$t('label.crane'),
                                        placement: 'top-end'
                                    }),expression:"{\n                                        content:$t('label.toView')+' '+$t('label.crane'),\n                                        placement: 'top-end'\n                                    }"}],staticClass:"rounded",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.activarModal()}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)]},proxy:true}]),model:{value:(_vm.$v.CraneFiltre.$model),callback:function ($$v) {_vm.$set(_vm.$v.CraneFiltre, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.CraneFiltre.$model"}})],1)],1)],1),_c('CCol',{attrs:{"sm":"12","lg":"4"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"label":_vm.$t('label.alias'),"horizontal":{ label: 'col-sm-12 col-lg-3 text-right required', input: 'col-sm-12 col-lg-9'},"placeholder":_vm.$t('label.alias'),"addLabelClasses":"text-right required"},model:{value:(_vm.CraneAlias),callback:function ($$v) {_vm.CraneAlias=$$v},expression:"CraneAlias"}})],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"12","lg":"auto"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content:_vm.$t('label.add'),
                        placement: 'top-end'
                    }),expression:"{\n                        content:$t('label.add'),\n                        placement: 'top-end'\n                    }"}],staticClass:"mr-1",attrs:{"color":"add","size":"sm","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.Submit()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.clearFields'), placement: 'top-end'}),expression:"{content: $t('label.clearFields'), placement: 'top-end'}"}],attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.reset(true, false)}}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fieldsGruas,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"sorter":"","noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","column-filter":"","hover":""},scopedSlots:_vm._u([{key:"acciones",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.edit')+' '+_vm.$t('label.crane'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.edit')+' '+$t('label.crane'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.EditCrane(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.inactivateCranePlanification'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.inactivateCranePlanification'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.RemoveCrane(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1),_c('ModalEditDeckCrane',{attrs:{"modal":_vm.ModalVisitCrane,"CraneItems":_vm.CraneItems},on:{"Close-Modal":_vm.reset}}),_c('AppCraneCrud',{attrs:{"modal":_vm.ModalCrane,"isEdit":_vm.isEdit,"CraneId":_vm.CraneFiltre},on:{"Close-Modal":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }